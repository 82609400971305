.App {
  /* text-align: center; */
  height: 100vh;
}

.loader {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.pull-right {
  float: right;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.m-20 {
  margin: 20px;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mw-200 {
  min-width: 200px;
}
.mr-200 {
  margin-right: 200px !important;
}

.p-30 {
  padding: 30px;
}

.link {
  color: #1890ff;
}

.link:hover {
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
