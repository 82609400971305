.superior .full_name {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}

.superior .position {
  margin-bottom: 5px;
}

.superior .email {
  color: #1890ff;
}

.superior {
  margin-bottom: 20px;
}

.square .ant-image-img,
.square .ant-image-mask {
  border-radius: 4px !important;
}

.document {
  display: flex;
  margin-bottom: 5px;
  margin-left: 5px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.document:hover {
  background-color: #f0f2f5;
  padding-right: 10px;
}

.document .info {
  padding: 10px;
  max-width: 70%;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.document .icon {
  font-size: 36px;
  width: 100px;
  height: 100px;
  position: relative;
  border: 1px solid;
}
.document .icon span {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.document img.ant-image-img {
  object-fit: cover;
}

.documentations {
  max-height: 60vh;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 5px;
}

.document .delete {
  position: absolute;
  top: -5px;
  right: 0;
  font-size: 20px;
  display: none;
}

.document:hover .delete {
  display: block;
}
