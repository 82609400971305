.right_menu {
  justify-content: end;
  height: 100%;
}

#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  /* background: #fff; */
}

.site-layout-background.content {
  height: calc(100vh - 105px);
  overflow: hidden;
  overflow-y: auto;
}

.vh_100 {
  height: 100vh;
}

.button_menu {
  background-color: transparent;
  border: none;
}
