.employ-profil {
  /* padding: 50px 100px; */
}

.employ-profil .header {
  padding: 50px 100px;
  /* background: rgb(30, 38, 61); */
  background: linear-gradient(
    90deg,
    rgba(30, 38, 61, 1) 0%,
    rgba(50, 59, 90, 1) 55%,
    rgba(66, 79, 115, 1) 100%
  );
  color: #fff;
}

.employ-profil h2 {
  font-weight: 700;
}

.employ-profil .header .title {
  color: #fff;
  font-size: 35px;
  margin-bottom: 20px;
}

.employ-profil .header .data {
  font-size: 16px;
}

.employ-profil .ant-image-img,
.employ-profil .ant-image-mask {
  border-radius: 100px;
}

.employ-profil .wrapper {
  min-height: 100px;
  margin: 20px 100px;
}
.employ-profil .wrapper .first {
  background-color: #fff;
  border-radius: 15px;
  top: -50px;
  position: relative;
  margin-bottom: 10px;
}

.edit-employe {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  position: relative;
}

.edit-employe .steps-content {
  max-width: 600px;
}

.steps-content {
  padding: 50px 0;
}

.steps-action {
  margin-top: 24px;
}

.lh-35 {
  line-height: 35px;
}

.action-bar input.mw-300 {
  max-width: 300px;
}

.action-bar .right {
  float: right;
}
